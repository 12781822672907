import Vue from 'vue'
import VueRouter from 'vue-router'
import Survey from '../views/Survey.vue'
import SurveyBur from '../views/Survey-Bur.vue'
import SurveyEth from '../views/Survey-Eth.vue'
import SurveyFar from '../views/Survey-Far.vue'
import SurveyFre from '../views/Survey-Fre.vue'
import SurveyMan from '../views/Survey-Man.vue'
import SurveyNep from '../views/Survey-Nep.vue'
import SurveySpa from '../views/Survey-Spa.vue'
import SurveyVie from '../views/Survey-Vie.vue'


Vue.use(VueRouter)

const routes = [
    {
        path: '/English',
        name: 'English',
        component: Survey,
    },
    {
        path: '/Burmese',
        name: 'Burmese',
        component: SurveyBur,
    },
    {
        path: '/Amharic',
        name: 'Ethiopian',
        component: SurveyEth,
    },
    {
        path: '/Farsi',
        name: 'Farsi',
        component: SurveyFar,
    },
    {
        path: '/French',
        name: 'French',
        component: SurveyFre,
    },
    {
        path: '/Mandinka',
        name: 'Mandinka',
        component: SurveyMan,
    },
    {
        path: '/Nepali',
        name: 'Nepalese',
        component: SurveyNep,
    },
    {
        path: '/Spanish',
        name: 'Spanish',
        component: SurveySpa,
    },
    {
        path: '/Vietnamese',
        name: 'Vietnamese',
        component: SurveyVie,
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
